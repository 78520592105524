<template>
    <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <!-- <div class="app-splash-loader"></div> -->
                    <v-progress-circular indeterminate color="grey lighten-1" size="36" width="4" class="mt-6"></v-progress-circular>
                    <!-- <v-progress-linear indeterminate color="grey lighten-1" width="4" class="mt-6"></v-progress-linear> -->
                </v-row>
            </template>
            <template v-if="isViewReady">
                <v-row justify="center" class="py-5">
                    <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                <template v-if="inboundAccessError">
                    <v-card class="pa-0 mt-5">
                        <v-toolbar dense flat class="red">
                            <v-toolbar-title class="white--text">Access denied</v-toolbar-title>
                        </v-toolbar>
                        <!-- <v-system-bar color="red darken-2">
                        </v-system-bar> -->
                        <v-card-text>
                            <p class="text-body-1 ma-0">
                            Inbound access prohibited. To log in, start from the application's main page.
                            </p>
                        </v-card-text>
                    </v-card>
                </template>

                <template v-if="loginError || redirectError || notRegisteredError">
                    <v-card class="pa-0 mt-5">
                    <v-app-bar :style="cardTitleBarStyle" flat>
                        <v-toolbar-title :style="cardTitleBarTextStyle">Sign in to {{ displayName }}</v-toolbar-title>
                    </v-app-bar>
                        <!-- <v-toolbar dense flat class="red">
                            <v-toolbar-title class="white--text">Login Error</v-toolbar-title>
                        </v-toolbar> -->
                        <!-- <v-system-bar color="red darken-2">
                        </v-system-bar> -->
                        <v-card-text>
                            <p class="text-body-1 ma-0" v-if="loginError">
                            We were not able to process your request. To log in, start from the application's main page.
                            </p>
                            <p class="text-body-1 ma-0" v-if="redirectError">
                            We were not able to process your request.
                            </p>
                            <p class="text-body-1 ma-0" v-if="notRegisteredError">
                            You are not registered with that email address.
                            </p>
                            <v-btn text small :style="primaryButtonStyle" :href="websiteURL" class="mt-4" v-if="websiteURL">
                                Continue
                            </v-btn>
                            <!-- <v-alert border="left" color="red" colored-border class="mt-8">
                                <p class="ma-0 pa-0">Check your inbox for a link to continue.</p>
                                <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="isEmailEditable">If you don't receive an email, check that the username is correct.</p>
                            </v-alert> -->
                        </v-card-text>
                    </v-card>
                </template>

                <template v-if="serverError">
                    <v-card class="pa-0 mt-5">
                    <v-app-bar :style="cardTitleBarStyle" flat>
                        <!-- TODO: should we use an amber bar here? -->
                        <v-toolbar-title :style="cardTitleBarTextStyle">Sign in to {{ displayName }}</v-toolbar-title>
                    </v-app-bar>
                        <!-- <v-toolbar dense flat class="red">
                            <v-toolbar-title class="white--text">Login Error</v-toolbar-title>
                        </v-toolbar> -->
                        <!-- <v-system-bar color="red darken-2">
                        </v-system-bar> -->
                        <v-card-text>
                            <p class="text-body-1 ma-0">
                            The server is temporarily unavailable. Please wait -- we will automatically retry your request.
                            </p>
                            <!-- TODO: make a retry countdown like 15 seconds counting down until zero, when it's zero enable the button -->
                            <v-btn text small :style="primaryButtonStyle" @click="retryLoginRequest" class="mt-4">
                                Retry
                            </v-btn>
                            <!-- <v-alert border="left" color="red" colored-border class="mt-8">
                                <p class="ma-0 pa-0">Check your inbox for a link to continue.</p>
                                <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="isEmailEditable">If you don't receive an email, check that the username is correct.</p>
                            </v-alert> -->
                        </v-card-text>
                    </v-card>
                </template>
                <template v-if="!isError">
                    <v-row class="pa-0 ma-0" justify="center">
                        <v-col>
                            <!-- The pseudonym or email with the "switch user" button must ONLY be displayed if
                            the pseudonym or email actually exists and we have an authentication workflow.
                            Invalid or non-existent pseudonym or email can still be edited via the appropriate
                            component. So the user should see EITHER a "switch user" component and an authentication
                            step or error message, OR a pseudonym or email input and NO "switch user" component.
                            It would not make sense to show a "switch user" component at the same we show a
                            pseudonym or email input component. -->
                            <template v-if="pseudonym">
                                <p class="text-center">{{ pseudonym }}</p>
                            </template>
                            <template v-if="email">
                                <p class="text-center">{{ email }}</p>
                            </template>
                            <template v-if="displaySwitchUser">
                                <v-form class="text-center">
                                    <v-btn text small :color="primaryColor" @click="switchProfile()">Change account</v-btn> <!-- previuosly color="blue darken-2" -->
                                </v-form>
                            </template>
                        </v-col>
                    </v-row>
                    <v-card elevation="4" class="pa-0 mt-5">
                        <v-app-bar :style="cardTitleBarStyle" flat>
                            <v-toolbar-title :style="cardTitleBarTextStyle">Sign in to {{ displayName }}</v-toolbar-title>
                            <v-progress-linear
                                :active="isLoading"
                                :indeterminate="isLoading"
                                absolute
                                bottom
                                :color="accentColor"
                            ></v-progress-linear>
                        </v-app-bar>
                        <v-row no-gutters class="px-4 pb-4 pt-8">
                            <v-col>

                        <template v-if="displayPseudonymForm">
                            <!-- :allowSwitchUser="allowSwitchUser" -->
                            <LoginAliasPseudonymForm :id="loginRequestId" :allowSwitchUser="allowSwitchUser" :display="displayPseudonymForm" :etag="etag" :pseudonym="pseudonymParam" @pseudonym="onPseudonymResult" @forgotPseudonym="startAccessRecovery"></LoginAliasPseudonymForm>
                        </template>

                        <template v-if="displayEmailForm">
                            <!-- :allowSwitchUser="allowSwitchUser" -->
                            <LoginAliasEmailForm :id="loginRequestId" :allowSwitchUser="allowSwitchUser" :display="displayEmailForm" :etag="etag" :email="emailParam" @email="onEmailResult" @forgotEmail="startAccessRecovery"></LoginAliasEmailForm>
                        </template>

                        <template v-if="displayPasswordForm">
                            <LoginPasswordForm :id="loginRequestId" :display="displayPasswordForm" :etag="etag" @password="onPasswordResult" @forgotPassword="startAccessRecovery"></LoginPasswordForm>
                        </template>

                        <template v-if="displayLoginShieldForm">
                            <LoginShieldForm :id="loginRequestId" :display="displayLoginShieldForm" :etag="etag" @loginshield="onLoginShieldResult" @newAuthenticator="startAccessRecovery"></LoginShieldForm>
                        </template>

                            </v-col>
                        </v-row>
                    </v-card>
                </template>

                <!-- <v-row justify="center"> -->
                    <!-- <h1 v-show="loginUserAliasInput" class="text-h4 font-weight-light">Login</h1>
                    <h1 v-show="loginWithLoginShield && !isActivatingLoginShield" class="text-h4 font-weight-light">Login</h1>
                    <h1 v-show="loginWithLoginShield && isActivatingLoginShield" class="text-h4 font-weight-light">Account</h1> -->
                    <!-- <h1 class="text-h4 font-weight-light" v-show="isActivatingLoginShield">Account</h1>
                    <h1 class="text-h4 font-weight-light" v-show="!isActivatingLoginShield">Login</h1> -->
                <!-- </v-row> -->
                <!-- <v-row justify="center" class="py-5">
                    <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                        <v-card elevation="4" class="px-10 py-5 pt-10 mt-5">
                            <p v-if="!loginshieldStartError">Please wait...</p>
                        </v-card>
                        <v-alert border="left" color="amber darken-2" colored-border v-if="startError">
                            Something went wrong.
                        </v-alert>
                        <v-row justify="center" class="my-2" v-if="startError" no-gutters>
                            <p class="text-body-1 font-weight-light red--text">Login failed</p>
                        </v-row>
                    </v-col>
                </v-row> -->
                <template v-if="!inboundAccessError">
                        <!-- <v-row justify="center" class="mt-8" v-if="tokenError" no-gutters> -->
                        <v-alert border="left" color="red darken-2" colored-border class="mt-8" v-if="tokenError">
                            <p class="ma-0 pa-0 text-body-1 font-weight-light red--text">Token is expired or invalid.</p>
                        </v-alert>
                        <!-- </v-row> -->
                        <!-- <v-row justify="center" class="mt-8" v-if="userAliasError" no-gutters>
                            <p class="text-body-1 font-weight-light red--text">Login failed</p>
                        </v-row> -->
                        <v-row justify="center" class="mt-8" v-if="passwordError" no-gutters>
                            <p class="text-body-1 font-weight-light red--text">Incorrect username or password</p>
                        </v-row>
                        <v-row justify="center" class="mt-8" v-if="redirectOriginMismatchError" no-gutters>
                            <p class="text-body-1 font-weight-light red--text">Configuration error</p>
                            <p>
                                The application asked us to redirect you to a URL that does not match the application's configuration.
                            </p>
                            <p>
                                <router-link :to="{ name: 'realm-dashboard', params: { realm: this.$route.params.realm } }">Continue to dashboard</router-link>
                            </p>
                        </v-row>
                        <!-- <v-row justify="center" class="mt-8" v-if="recoverAccessError" no-gutters>
                            <p class="text-body-1 font-weight-light red--text">Cannot send access recovery email</p>
                        </v-row> -->
                </template>
                    </v-col>
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>

<style lang="css">
.v-input__append-outer button {
    /* position: absolute; */
    top: -8px;
}

/* .v-text-field input:disabled { */
.theme--light.v-input--is-disabled input {
    color: rgba(0,0,0,1) !important;
}

/* this works to display under the logo but decided to use v-progress-circular instead -->
/* #F5F5F5 is grey lighten-4, to make smoother transition to brand color palette; prior color was #9FA8DA indigo lighten-3 */
/* #BDBDBD is grey lighten-1, to make smoother transition to brand color palette; prior color was #3F51B5 indigo */
/*
.app-splash-loader {
    border: 4px solid #F5F5F5;
    border-top: 4px solid #BDBDBD;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: relative;
    top:36px;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
*/
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { toMillis } from '@libertyio/time-util-js';
import { USER_ALIAS_MODE_EMAIL, USER_ALIAS_MODE_PSEUDONYM, AUTHN_EMAIL, AUTHN_PSEUDONYM, AUTHN_PASSWORD, AUTHN_LOGINSHIELD, INTENT_LOGIN, /* INTENT_SSO, */ INTENT_PREFS, INTENT_REDIRECT, INTENT_SETUP, INTENT_RECOVERY, RESPONSE_TYPE_FAULT, RESPONSE_TYPE_INTENT } from '@/sdk/loginfront/login_api_constants.js'; // '@loginfront/login-api-constants-js';
import LoginAliasEmailForm from '@/components/authn/LoginAliasEmailForm.vue';
import LoginAliasPseudonymForm from '@/components/authn/LoginAliasPseudonymForm.vue';
import LoginPasswordForm from '@/components/authn/LoginPasswordForm.vue';
import LoginShieldForm from '@/components/authn/LoginShieldForm.vue';

// TODO: fix this import issue:
// 2023-04-15 something is wrong with the import of INTENT_SSO, there's no error in dev, but in production it is undefined; temporary workaround to define constant HERE; remove the workaround when the import is fixed
const INTENT_SSO = 'sso';

export default {
    components: {
        LoginAliasEmailForm,
        LoginAliasPseudonymForm,
        LoginPasswordForm,
        LoginShieldForm,
    },
    data: () => ({
        isViewReady: false,
        loginRequestId: null,
        loginToken: null,
        tokenError: false,
        serverError: false,
        notRegisteredError: false,
        from: null,
        next: null, // the next authentication step to display
        allowSwitchUser: true,
        loginUserAliasInput: true,
        recoverAccessInput: false,
        pseudonym: null,
        password: null,
        email: null,
        pseudonymParam: null, // pre-fill value for pseudonym during the pseudonym step only
        emailParam: null, // pre-fill value for email during the email step only
        isUserAliasEditable: true,
        startError: false,
        pseudonymError: false,
        passwordError: false,
        recoverAccessError: false,
        emailNotFoundError: false,
        loginshieldStartError: false,
        isRememberMeChecked: null,
        // email verification
        // isEmailSent: false, // REMOVED BECAUSE NOW REDIRECTING TO CRYPTIUM ID FOR THIS
        // linkExpires: null, // REMOVED BECAUSE NOW REDIRECTING TO CRYPTIUM ID FOR THIS
        dialogAccessRecoveryHelp: false,
        // redirect origin mismatch
        redirectOriginMismatchError: false,
        // inbound access
        inboundAccessError: false,
        // login request error
        loginError: false,
        // current time; update this to force updates in child components
        etag: Date.now(),
    }),

    computed: {
        ...mapState({
            // isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            palette: (state) => state.palette,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        websiteURL() {
            return this.realmInfo?.website_url;
        },
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isUserPseudonymFormComplete() {
            return typeof this.pseudonym === 'string' && this.userAlias.length > 0;
        },
        isUserEmailFormComplete() {
            return typeof this.email === 'string' && this.email.length > 0;
        },
        isRecoverAccessFormComplete() {
            return typeof this.email === 'string' && this.email.length > 0;
        },
        displayName() {
            return this.realmInfo?.display_name;
        },
        displaySwitchUser() {
            return this.allowSwitchUser && (
                (this.realmInfo?.user_alias_mode === USER_ALIAS_MODE_PSEUDONYM && this.pseudonym)
                || (this.realmInfo?.user_alias_mode === USER_ALIAS_MODE_EMAIL && this.email)
            );
        },
        displayEmailForm() {
            return this.next === AUTHN_EMAIL;
        },
        displayPseudonymForm() {
            return this.next === AUTHN_PSEUDONYM;
        },
        displayPasswordForm() {
            return this.next === AUTHN_PASSWORD;
        },
        displayLoginShieldForm() {
            return this.next === AUTHN_LOGINSHIELD;
        },
        // errors that should prevent main sign in card from appearing
        isError() {
            return this.inboundAccessError || this.loginError || this.serverError || this.notRegisteredError;
        },
        /*
        userAliasInputLabel() {
            let text;
            switch (this.realmInfo?.user_alias_mode) {
            case USER_ALIAS_MODE_EMAIL:
                text = 'Email';
                break;
            case USER_ALIAS_MODE_PSEUDONYM:
                text = 'Username';
                break;
            default:
                text = this.realmInfo?.user_alias_mode;
                break;
            }
            return text;
        },
        */
        userAliasModeEmail() {
            return this.realmInfo?.user_alias_mode === USER_ALIAS_MODE_EMAIL;
        },
        userAliasModePseudonym() {
            return this.realmInfo?.user_alias_mode === USER_ALIAS_MODE_PSEUDONYM;
        },
    },

    watch: {
        /*
        // TODO: remove this; not needed anymore on this view because the server already checks if the user is authenticated and server's login request status responses take this into account
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        */
        isRememberMeChecked(value) {
            console.log(`Login.vue: isRememberMeChecked watcher: storing ${value}`);
            localStorage.setItem('rememberMe', value);
        },
        focus() {
            this.getLoginRequestStatus();
        },
    },

    methods: {
        async init() {
            if (this.$route.query.pseudonym) {
                this.pseudonymParam = this.$route.query.pseudonym;
            }

            if (this.$route.query.email) {
                this.emailParam = this.$route.query.email;
            }

            // if another view redirected here as part of a larger interaction like creating
            // an account, there should be an interaction id in the query
            this.interactionId = this.$route.query.i;

            // if another view redirected here with the intent of returning, it has to provide
            // the path and optional query parameters to redirect the user after login; the path
            // MUST be a relative path because this is not the right place for unrelated logic
            // about where we can redirect the user; the 'next' route could then redirect the
            // user to an offsite URL if needed
            const { next } = this.$route.query;
            if (typeof next === 'string' && next.startsWith('/')) {
                this.nextRoute = next;
            }

            // if a client application redirected a user here to login, there should be
            // a login token in the query, and this indicates the start of a new login
            // request
            if (this.$route.query.token) {
                console.log('Login.vue: start login with token in query');
                this.loginToken = this.$route.query.token;
                await this.startLogin(this.loginToken);
                return;
            }

            if (this.$route.query.mode === 'resume-loginshield' && this.$route.query.loginshield) {
                this.isViewReady = true;
                // if the client arrives with this query parameter it
                // indicates a loginshield authentication in progress
                // displayed a safety notice and the user followed one
                // of the redirect options to arrive here
                console.log('login: resume loginshield authentication mode');
                this.next = AUTHN_LOGINSHIELD;
                this.etag = Date.now();
                // TODO: XYZZY we need some input to the loginshield form with the token maybe ?   this.loginshieldToken = this.$route.query.loginshield and then pass that token to the loginshieldform component??
                setTimeout(() => { this.$nextTick(() => this.resumeLoginShield({ forward: this.$route.query.loginshield, rememberMe: true })); }, 1); /* this.isRememberMeChecked */
                return;
            }

            // if there's already a login request in progress, there should be a login request id in the query
            if (this.$route.query.r) {
                this.loginRequestId = this.$route.query.r;
                await this.getLoginRequestStatus();
                return;
            }

            // user didn't arrive with a token or login request id, and is not authenticated; check if realm allows inbound access
            console.log(`Login.vue: init: no token or login request id; allow inbound? ${JSON.stringify(this.realmInfo)}`);
            if (this.realmInfo?.allow_inbound) {
                await this.startLogin(); // without a token this will create a new login request, ONLY if realm allows inbound access
                return;
            }

            // TODO: is this even needed anymore?   remember me should be done by server via session cookie.... not local storage
            // const rememberMeStored = localStorage.getItem('rememberMe');
            // this.isRememberMeChecked = rememberMeStored === 'true';

            if (!this.loginToken && !this.loginRequestId) {
                this.loginError = true;
                // TODO: show inbound access error ?
                // this.inboundAccessError = true;
            }

            this.isViewReady = true;
        },
        resetErrors() {
            this.emailError = false;
            this.pseudonymError = false;
            this.passwordError = false;
            this.loginshieldStartError = false;
            this.notRegisteredError = false;
            this.loginError = false;
            this.serverError = false;
            this.startError = false;
            this.redirectError = false;
        },
        /**
         * This is used to switch accounts; it only works if 'allowSwitchUser' is enabled for this login request.
         */
        async resetLoginRequest() {
            try {
                this.$store.commit('loading', { resetLoginRequest: true });
                const loginStatus = await this.$client.realm(this.$route.params.realm).authn.resetLoginRequest(this.loginRequestId);
                console.log(`resetLoginRequest response: ${JSON.stringify(loginStatus)}`);

                this.prepareView(loginStatus);
            } catch (err) {
                console.log('resetLoginRequest error', err);
                if (err.response?.status >= 500) {
                    this.serverError = true;
                } else if (err.response?.status >= 400 && err.response?.status < 500) {
                    this.loginError = true;
                } else {
                    this.loginError = true;
                }
            } finally {
                this.$store.commit('loading', { resetLoginRequest: false });
            }
        },
        async resetLoginForm() {
            // this.isEmailSent = false;
            // this.linkExpires = null;
            this.next = null;
            this.etag = Date.now();
            this.recoverAccessInput = false;
            this.email = '';
            this.emailParam = this.$route.query.email; // may be null or undefined
            this.pseudonym = '';
            this.pseudonymParam = this.$route.query.pseudonym; // may be null or undefined
            this.password = '';
            // this.emailError = false;
            // this.pseudonymError = false;
            // this.passwordError = false;
            this.recoverAccessError = false;
            this.loginshieldStartError = false;
            this.redirectOriginMismatchError = false;
            await this.resetLoginRequest();
            // this.$refs.pseudonymField.reset();
            // this.$refs.emailField.reset();
            // this.$refs.passwordField.reset();
            this.$activateInput('userAliasField'); // TODO: we need to check realm settings to see if it should be focused on pseudonym or email field
        },
        switchProfile() {
            // this.isLoginUserAliasError = false;
            // this.isLoginPasswordError = false;
            // this.loginUserAliasInput = true;
            this.resetLoginForm();
        },
        async startLogin(token) {
            try {
                this.$store.commit('loading', { startLogin: true });
                const loginStatus = await this.$client.realm(this.$route.params.realm).authn.startLogin({
                    token,
                });
                console.log(`startLogin response: ${JSON.stringify(loginStatus)}`);

                if (loginStatus.id) {
                    // remove token from url and replace it with the login request id
                    this.$router.replace({ name: 'realm-login', params: { realm: this.$route.params.realm }, query: { r: loginStatus.id, t: Date.now() } });
                }

                this.prepareView(loginStatus);
                /*
                if (id) {
                }
                if (pseudonym) {
                    this.pseudonym = pseudonym;
                } else if (this.$route.query.pseudonym) {
                    this.pseudonym = this.$route.query.pseudonym;
                } else if (alias) {
                    this.pseudonym = alias;
                } else if (this.$route.query.alias) {
                    this.pseudonym = this.$route.query.alias;
                }

                if (email) {
                    this.email = email;
                } else if (this.$route.query.email) {
                    this.email = this.$route.query.email;
                }

                // NOTE: this actually applies to BOTH email and pseudonym, whichever one is required, so does not need to be renamed
                if (requireAlias) {
                    this.isUserAliasEditable = false;
                }

                if (intent === 'activate-loginshield') {
                    this.intent = intent;

                    // new user registration or reset
                    console.log('login: activate loginshield mode');
                    this.loginUserAliasInput = false;
                    this.loginWithLoginShield = true;
                    this.isActivatingLoginShield = true;
                    setTimeout(() => {
                        this.$nextTick(() => this.startLoginShield({
                            mode: 'activate-loginshield',
                            interactionId: this.interactionId,
                            nextRoute: this.next,
                            username: this.userAlias, // TODO: when loginfront is merged with loginshield, it will be for whatever alias (pseudonym or email) the user already entered
                            rememberMe: true, / * this.isRememberMeChecked * /
                        }));
                    }, 1);
                    return;
                }

                // TODO: server reports that user could continue as they are
                if (authenticated) {
                //     // TODO: show a view with user's current profile, allow user to continue as current user or switch account;  to continue as current user we need an API call to server to confirm that choice and get the redirect link, that's the part that isn't implemented yet on server, and the UI described here isn't implemeneted yet on this page
                }

                // if (this.isAuthenticated) {
                //     console.log('login with token, but already authenticated!!! prompt user to continue as current user, or to switch users');
                // }

                / *
                i f (mechanism === 'userAlias') {
                    this.loginUserAliasInput = true;
                    this.loginWithLoginShield = false;
                }
                if (mechanism === 'password') {
                    this.loginUserAliasInput = false;
                    this.loginWithLoginShield = false;
                    this.$activateInput('passwordField');
                } else if (mechanism === 'loginshield') {
                    this.loginUserAliasInput = false;
                    this.loginWithLoginShield = true;
                    this.startLoginShield({
                        username: this.userAlias,
                        interactionId: this.interactionId,
                        nextRoute: this.next,
                        rememberMe: true, // this.isRememberMeChecked
                    });
                } else {
                    this.startError = true;
                    this.resetLoginForm();
                }
                * /

                // The login service already got the userAlias from the client application,
                // we already pre-fill it (see above) but we can go one step further and
                // auto-submit that userAlias to get to the next step. This is especially
                // useful for client applications that prompt the user for their userAlias
                // before redirecting to LoginFront, because it's confusing and annoying
                // to enter a userAlias, then be redirected and have to enter it again
                // (even if it's pre-filled)
                if (alias) {
                    this.loginUserAlias();
                }
                */
            } catch (err) {
                console.log('startLogin error', err);
                if (err.response?.status >= 500) {
                    this.serverError = true;
                } else if (err.response?.status >= 400 && err.response?.status < 500) {
                    this.loginError = true;
                    if (this.$route.query.token) {
                        // remove token from url
                        this.$router.replace({ name: 'realm-login', params: { realm: this.$route.params.realm }, query: { t: Date.now() } });
                    }
                } else {
                    this.loginError = true;
                }
            } finally {
                this.$store.commit('loading', { startLogin: false });
            }
        },
        prepareView(loginStatus) {
            try {
                const {
                    id, // the login request id
                    authenticated, // true if user is authenticated for this request (separate from user's session that may be authenticated already for another user)
                    allow_switch_user: allowSwitchUser, // this replaces 'require_alias' ... if user is allowed to switch users, this will be true; default is true, so we only disable it if server indicates switching is not allowed for this request
                    type, // the response type, RESPONSE_TYPE_FAULT or RESPONSE_TYPE_INTENT
                    brandprofile, // the brandprofile to use for this request, which may be different than the realm's default brandprofile (it could be the brandprofile for the app corresponding to the client that initiated the login)
                    // intent, // 'login', 'prefs', 'setup', 'activate-loginshield', etc.
                    // redirect, // if server needs to redirect user somewhere, this will be present
                    // error, // if server indicates there is a problem with this login request and we cannot continue
                    // fault, // if server indicates there is a problem with this login request and we cannot continue
                } = loginStatus;

                if (id) {
                    this.loginRequestId = id;
                }

                if (typeof allowSwitchUser === 'boolean') {
                    this.allowSwitchUser = allowSwitchUser;
                }

                if (authenticated) {
                    this.authenticated = authenticated;
                }

                if (brandprofile) {
                    console.log(`Login.vue request ${id} brandprofile ${brandprofile}`);
                    // this.$store.commit('requestBrandprofile', { intent, requestId: id, brandprofile }); // TODO:  maybe a request id -> brandprofile ??? because we need to know when to STOP showing it and go back to the realm's brandprofile (if that happens)
                    this.$store.dispatch('switchBrand', { brandprofile });
                }

                if (type === RESPONSE_TYPE_FAULT) {
                    const { fault } = loginStatus;
                    // show an error message ,depending on specific fault
                    console.log(`Login.vue: prepareView: fault: ${JSON.stringify(fault)}`);
                    switch (fault) {
                    case 'not_registered':
                        this.notRegisteredError = true;
                        break;
                    default:
                        this.loginError = true;
                        break;
                    }
                    this.isViewReady = true;
                    return;
                }

                if (type === RESPONSE_TYPE_INTENT) {
                    const { intent, intent_params: intentParams, pseudonym, email } = loginStatus;
                    console.log(`Login.vue: prepareView: intent ${intent} with params ${JSON.stringify(intentParams)}`);
                    // pseudonym or email will be present in the login request status if the request
                    // already specified these or if the user already did the corresponding input step,
                    // and we can show the user alias as context for the current step
                    if (pseudonym) {
                        this.pseudonym = pseudonym; // user already selected for the login request
                    }
                    if (email) {
                        this.email = email; // user already selected for the login request
                    }
                    switch (intent) {
                    case INTENT_LOGIN: {
                        // next, // this is the next authentication step to show
                        const { next } = intentParams;
                        if (next === AUTHN_PSEUDONYM) {
                            // pre-fill value for the pseudonym step; not the same as knowing the pseudonym already for the request (above)
                            this.pseudonymParam = intentParams.pseudonym ?? pseudonym ?? this.$route.query.pseudonym;
                        }
                        if (next === AUTHN_EMAIL) {
                            // pre-fill value for the email step; not the same as knowing the email already for the request (above)
                            this.emailParam = intentParams.email ?? email ?? this.$route.query.email;
                        }
                        if (next) {
                            console.log(`prepareView: next ${next} email ${JSON.stringify(email)} pseudonym ${JSON.stringify(pseudonym)}`);
                            this.next = next;
                            this.etag = Date.now();
                        }
                        this.isViewReady = true;
                        return;
                    }
                    case INTENT_SSO: {
                        const { sso_app_tag: ssoAppTag, sso_request_id: ssoRequestId } = intentParams;
                        this.$router.replace({ name: 'realm-sso', params: { realm: this.$route.params.realm }, query: { sso_app_tag: ssoAppTag, sso_request_id: ssoRequestId, t: Date.now() } });
                        return;
                    }
                    case INTENT_PREFS: {
                        // TODO: should this be going to 'realm-user-preferences' instead of 'realm-user-preferences-authn' ?
                        this.$router.replace({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm }, query: { t: Date.now() } });
                        return;
                    }
                    case INTENT_REDIRECT: {
                        const { redirect } = intentParams;
                        if (redirect) {
                            console.log(`prepareView: redirect ${redirect}`);
                            if (typeof window.location.replace === 'function') {
                                window.location.replace(redirect);
                            } else {
                                window.location.href = redirect;
                            }
                            return;
                        }
                        console.error(`Login.vue: prepareView: invalid redirect intent without redirect url: ${JSON.stringify(intentParams)}`);
                        this.redirectError = true;
                        this.isViewReady = true;
                        return;
                    }
                    /*
                    TODO: some other case for when user is authenticated and the process is done. ???
                    so we either do this:
                        this.redirectAfterLogin({ intent });
                    or it should be an intent that we forward the user there and then from there it will redirect the user to wherever ??? or maybe intent setup, intent prfes, etc.
                    if (authenticated ) {
                        this.redirectAfterLogin();
                        return;
                    }
                    */
                    default:
                        console.error(`Login.vue: prepareView: unsupported intent ${JSON.stringify(intent)}`); // TODO :we need to support more of the intents here to know what to do
                        // 2023-04-15 THESE ARE JUST TO DEBUG THE ISSUE WITH THE CONSTANT NOT BEING DEFINED
                        console.info(`Login.vue: sso intent: ${JSON.stringify(INTENT_SSO)}`);
                        console.info(`Login.vue: login intent: ${JSON.stringify(INTENT_LOGIN)}`);
                        console.info(`Login.vue: all constants: ${JSON.stringify({USER_ALIAS_MODE_EMAIL, USER_ALIAS_MODE_PSEUDONYM, AUTHN_EMAIL, AUTHN_PSEUDONYM, AUTHN_PASSWORD, AUTHN_LOGINSHIELD, INTENT_LOGIN, INTENT_SSO, INTENT_PREFS, INTENT_REDIRECT, INTENT_SETUP, INTENT_RECOVERY, RESPONSE_TYPE_FAULT, RESPONSE_TYPE_INTENT})}`); // eslint-disable-line
                        this.isViewReady = true;
                        return;
                    }
                }

                console.error(`Login.vue: prepareView: unsupported response type ${type}`);
            } catch (err) {
                console.error('prepareView error', err);
                this.loginError = true;
            }
        },
        async loginUserAlias() {
            try {
                /*
                if (!this.isUserAliasFormComplete) {
                    return;
                }
                */
                /*
                this.loginUserAliasInput = false;
                this.loginWithLoginShield = true;
                this.startLoginShield({
                    username: this.userAlias,
                    interactionId: this.interactionId,
                    nextRoute: this.next,
                    rememberMe: true,
                });
                */
                console.log('Login.vue: loginUserAlias');
                this.passwordError = false;

                this.$store.commit('loading', { loginUserAlias: true });
                const {
                    /* isAuthenticated, */
                    // intent, // could be 'signup' in conjunction with redirect, so we could show a card that says 'you need to sign up to continue, and here's the link' and show the redirect URL as a sign up link instead of automatically redirecting the user; it's our choice whether to show that step or redirect immediately
                    status,
                    redirect,
                    mechanism,
                    // route,
                    // expires,
                    error,
                } = await this.$client.realm(this.$route.params.realm).authn.loginWithUserAlias({
                    alias: this.userAlias,
                    token: this.loginToken,
                });
                if (error === 'setup') {
                    console.error('loginUserAlias: user authentication setup incomplete');
                    // TODO: instead of showing this message, we need to redirect user to complete their set up and then return here to finish authentication and go to where they were going.... so there should be an API request right here, with the current path+query as one of the parameters, so we can return the user here after set up, or just authenticate them, skip this workflow, and redirect them to where they were going after setup
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Please contact customer support', messages: 'Your profile is incomplete' });
                    return;
                }
                if (error) {
                    console.error(`loginUserAlias login error: ${JSON.stringify(error)}`);
                    this.userAliasError = true;
                    return;
                }
                if (status === 'redirect' && redirect) {
                    if (redirect.startsWith('/')) {
                        this.$router.replace(redirect);
                    } else if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        window.location.href = redirect;
                    }
                    return;
                }
                /*
                // TODO: now that we're using Cryptium ID for email verification, we can also remove the following elements from the UI:  isEmailSent, linkExpires
                if (route === 'email') {
                    this.isEmailSent = true;
                    this.linkExpires = expires;
                    return;
                }
                */
                if (mechanism === 'password') {
                    this.loginUserAliasInput = false;
                } else if (mechanism === 'loginshield') {
                    this.loginUserAliasInput = false;
                    this.loginWithLoginShield = true;
                    setTimeout(() => {
                        this.$nextTick(() => this.startLoginShield({
                            username: this.userAlias,
                            interactionId: this.interactionId,
                            nextRoute: this.next,
                            rememberMe: true, /* this.isRememberMeChecked */
                        }));
                    }, 1);
                } else {
                    this.passwordError = true;
                    this.resetLoginForm();
                }
            } catch (err) {
                console.error('loginuserAlias failed', err);
                if (err.response && [401, 403].includes(err.response.status) && this.loginToken) {
                    this.tokenError = true;
                    this.$router.replace({ name: 'realm-login', params: { realm: this.$route.params.realm }, query: { alias: this.$route.query.alias } });
                    this.loginToken = null;
                }
                if (err.response && [500, 502].includes(err.response.status)) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Service unavailable', message: 'This is temporary. Please try again.' });
                }
            } finally {
                this.$store.commit('loading', { loginUserAlias: false });
            }
        },
        async updateSession({ isAuthenticated }) {
            this.$store.commit('setSession', { ...this.session, isAuthenticated });
            if (isAuthenticated) {
                await this.$store.dispatch('loadUser');
            } else {
                this.$store.commit('setUser', {});
            }
        },
        async redirectAfterLogin({ intent, nextInteractionId } = {}) {
            if (intent) {
                switch (intent) {
                case 'prefs':
                    this.$router.replace({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm }, query: { t: Date.now() } });
                    return;
                default:
                    console.log(`redirectAfterLogin: unsupported intent: ${JSON.stringify(intent)}`);
                }
            }
            if (nextInteractionId) {
                const nextInteraction = await this.$store.dispatch('loadInteraction', nextInteractionId);
                console.log('finishLoginShield: next interaction: %o', nextInteraction);
                if (nextInteraction && nextInteraction.type) {
                    switch (nextInteraction.type) {
                    case 'require_login':
                        this.$router.replace(nextInteraction.state.redirect);
                        return;
                    default:
                        this.$router.replace({ name: 'realm-dashboard', query: { i: nextInteractionId }, params: { realm: this.$route.params.realm } });
                        return;
                    }
                }
            }
            if (this.next) {
                this.$router.replace({ path: this.next });
                return;
            }
            this.$router.replace({ name: 'realm-dashboard', params: { realm: this.$route.params.realm } });
        },
        retryLoginRequest() {
            window.location.reload();
        },
        async getLoginRequestStatus() {
            try {
                this.$store.commit('loading', { getLoginRequestStatus: true });
                const loginStatus = await this.$client.realm(this.$route.params.realm).authn.getLoginRequestStatus(this.loginRequestId);
                console.log(`getLoginRequestStatus response: ${JSON.stringify(loginStatus)}`);

                this.prepareView(loginStatus);
            } catch (err) {
                console.log('getLoginRequestStatus error', err);
                if (err.response?.status >= 500) {
                    this.serverError = true;
                } else if (err.response?.status >= 400 && err.response?.status < 500) {
                    this.loginError = true;
                } else {
                    this.loginError = true;
                }
            } finally {
                this.$store.commit('loading', { getLoginRequestStatus: false });
            }
        },
        async onPseudonymResult(event) {
            console.log(`onPseudonymResult: ${JSON.stringify(event)}`);
            return this.onResult(event);
        },
        async onEmailResult(event) {
            console.log(`onEmailResult: ${JSON.stringify(event)}`); // example: {"email":"sparky@example.com","intent":"login","intent_params":{"next":"password"}}
            const { email } = event;
            if (email) {
                this.email = email;
            }
            return this.onResult(event);
        },
        async onPasswordResult(event) {
            console.log(`onPasswordResult: ${JSON.stringify(event)}`);
            return this.onResult(event);
        },
        async onLoginShieldResult(event) {
            console.log(`onLoginShieldResult: ${JSON.stringify(event)}`);
            // TODO: the following USED TO BE in the onREsult function but seems to be loginshield specific, and can't find whre it's called here, so this might be kind o fok if loginshield component emit sloginshield event, and then we catch ithere.
            switch (event.status) {
            case 'verify':
                this.finishLoginShield({ verifyToken: event.verifyToken });
                break;
            case 'error':
                if (this.intent === 'activate-loginshield') {
                    this.$router.replace({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm } }); // TODO: this.redirectAfterLogin(); ?
                } else {
                    this.loginshieldStartError = true;
                    this.resetLoginForm();
                }
                break;
            case 'cancel':
                if (this.intent === 'activate-loginshield') {
                    this.$router.replace({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm } }); // TODO: this.redirectAfterLogin(); ?
                } else {
                    this.resetLoginForm();
                }
                break;
            default:
                console.error(`Login.vue: onResult: unknown status ${event.status}`);
            }
            return this.onResult(event);
        },
        async onResult(event) {
            const { intent, intent_params: intentParams } = event;
            switch (intent) {
            case INTENT_LOGIN: {
                const { next } = intentParams;
                if (next) {
                    this.next = next;
                    this.etag = Date.now();
                    // return;
                }
                // TODO: what do we do if there's no  'next' defined ?
                break;
            }
            case INTENT_SSO: {
                const { sso_app_tag: ssoAppTag, sso_request_id: ssoRequestId } = intentParams;
                this.$router.replace({ name: 'realm-sso', params: { realm: this.$route.params.realm }, query: { sso_app_tag: ssoAppTag, sso_request_id: ssoRequestId, t: Date.now() } });
                break;
            }
            case INTENT_PREFS:
                // NOTE: we need to put something in the query or in the sessoin so we can navigate user back to app later...  but not the login request id because that's over.
                this.$router.replace({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm }, query: { t: Date.now() } });
                break;
            case INTENT_REDIRECT: {
                // server may redirect user to Cryptium ID for email verification, or back to client app
                // when authentication is either successful or cancelled
                const redirect = intentParams?.redirect;
                if (redirect) {
                    this.redirect = redirect;
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        window.location.href = redirect;
                    }
                } else {
                    console.error(`onResult: unexpected event content: ${JSON.stringify(event)}`);
                    // this.emailError = true;
                }
                break;
            }
            case INTENT_SETUP:
                // NOTE: we need to put something in the query or in the sessoin so we can navigate user back to app later...  but not the login request id because that's over.
                this.$router.replace({ name: 'realm-profile-setup', params: { realm: this.$route.params.realm } });
                break;
            default:
                console.error(`onResult: unexpected event contentjonathan: ${JSON.stringify(event)}`);
                // this.emailError = true;
                break;
            }
        },
        async startAccessRecovery() {
            try {
                console.log(`startAccessRecovery for login request id ${this.loginRequestId}`);
                this.$store.commit('loading', { startAccessRecovery: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.startRecovery({
                    intent: INTENT_LOGIN,
                    login_request_id: this.loginRequestId,
                });
                /*
                // if email or other identity verification is required, user will be redirected to Cryptium ID
                if (response?.status === 'redirect' && response.redirect) {
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(response.redirect);
                    } else {
                        window.location.href = response.redirect;
                    }
                    return;
                }
                */
                if (response?.type === 'intent' && response.intent === INTENT_RECOVERY) {
                    const { id } = response.intent_params ?? {};
                    if (id) {
                        this.$router.push({ name: 'realm-access-recovery', params: { realm: this.$route.params.realm }, query: { id } });
                        return;
                    }
                    console.log('startAccessRecovery: response did not include a request id');
                }
                console.log('startAccessRecovery: unexpected response');
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to start access recovery', message: 'Is that a valid email address?', duration: toMillis({ seconds: 10 }) });
                this.recoverAccessError = true;
            } catch (err) {
                if (err.response && [500, 502].includes(err.response.status)) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Service unavailable', message: 'This is temporary. Please try again.', duration: toMillis({ seconds: 10 }) });
                    return;
                }
                if (err.response && [401, 403].includes(err.response.status)) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Expired', message: 'Please return to the application and sign in again.', duration: toMillis({ seconds: 10 }) });
                    return;
                }
                console.error('failed to send access recovery email', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to start access recovery', message: 'Please contact customer support', duration: toMillis({ seconds: 10 }) });
                this.recoverAccessError = true;
            } finally {
                this.$store.commit('loading', { startAccessRecovery: false });
            }
        },
    },

    mounted() {
        this.init();
    },
};
</script>
