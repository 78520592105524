/*!
Copyright (C) 2021 Cryptium Corporation. All rights reserved.
*/

/**
 * NOTE: this file is a COPY of src/main.js in @loginfront/login-api-constants-js.
 * Importing constants from that library used to work fine but for some reason
 * that stopped working in July 2023 and we started to see the constants being
 * imported as 'undefined' instead of their string values. No solution found so
 * far so this is a workaround to include a copy of the file here and import
 * from the same project instead.
 */

// authentication steps
const AUTHN_CRYPTIUM_ID = 'cryptium_id';
const AUTHN_EMAIL = 'email';
const AUTHN_PSEUDONYM = 'pseudonym';
const AUTHN_PASSWORD = 'password';
const AUTHN_LOGINSHIELD = 'loginshield'; // this refers to authentication by private key signature, which we accomplish via the LoginShield authenticator app; TODO: maybe rename it to something more specific like 'dsig' for digital signature, or just 'shield' for digital signature with approved cipherset and possibly geolocation verification, etc.
const AUTHN_OTP = 'otp';
const AUTHN_2FA = '2fa'; // a temporary code sent via EtherLink, email, SMS, etc.
const AUTHN_SETUP = 'setup'; // a page that asks user if they want to set up their authentication settings now since they don't have them yet; TODO: should this be in a different list ?? it's an authentication STEP but not an authentication MECHANISM , unless we consider someone creating a new account and not setting an authentication mecahnism to be a kidn of mechanism itself, meaning the user is known to be the profile owner because they just created it , but if we do that it shouldn't be called 'setup' we should have a different name for that
const LIST_AUTHN = [
    AUTHN_CRYPTIUM_ID,
    AUTHN_EMAIL,
    AUTHN_PSEUDONYM,
    AUTHN_PASSWORD,
    AUTHN_LOGINSHIELD,
    AUTHN_OTP,
    AUTHN_2FA,
    AUTHN_SETUP,
];

// faults
const FAULT_INVALID_EMAIL = 'invalid_email';
const FAULT_INVALID_PSEUDONYM = 'invalid_pseudonym';
const FAULT_INVALID_TOKEN = 'invalid_token';
const FAULT_NOT_REGISTERED = 'not_registered';
const FAULT_UNAUTHORIZED = 'unauthorized'; // when password is wrong, OTP is wrong, 2FA code is wrong, LoginShield check failed, etc.
// const FAULT_TOO_MANY_ATTEMPTS = 'too_many_attempts'; // when password is wrong, OTP is wrong, 2FA code is wrong, LoginShield check failed, etc.
const FAULT_ACCESS_DENIED = 'access_denied'; // final determination that login request failed
const LIST_FAULT = [
    FAULT_INVALID_EMAIL,
    FAULT_INVALID_PSEUDONYM,
    FAULT_INVALID_TOKEN,
    FAULT_NOT_REGISTERED,
    FAULT_UNAUTHORIZED,
    // FAULT_TOO_MANY_ATTEMPTS,
    FAULT_ACCESS_DENIED,
];

// interaction types
const IA_LOGIN = 'login';
const IA_SERVICE_SETUP = 'service_setup';
const IA_SHARE = 'share';
const IA_SIGNUP = 'signup';
const IA_RECOVERY = 'recovery';
const IA_VERIFY_EMAIL = 'verify_email';
const LIST_IA = [
    IA_LOGIN,
    IA_SERVICE_SETUP,
    IA_SHARE,
    IA_SIGNUP,
    IA_RECOVERY,
    IA_VERIFY_EMAIL,
];

// intents
const INTENT_LOGIN = 'login'; // when a client needs to authenticate a user and return to the same client
const INTENT_SIGNUP = 'signup';
const INTENT_SSO = 'sso'; // when a client needs to authenticate a user and redirect the user to any available realm application
const INTENT_RECOVERY = 'recovery';
const INTENT_PREFS = 'prefs'; // authentication preferences
const INTENT_ACTIVATE_LOGINSHIELD = 'activate-loginshield'; // this refers to activating the LoginShield authenticator app
const INTENT_SETUP = 'setup'; // NOTE: used in httpPostStartLogin, but we need to check if any clients still send this, or if it's obsolete and we can remove it
const INTENT_REDIRECT = 'redirect';
const LIST_INTENT = [
    INTENT_LOGIN,
    INTENT_SIGNUP,
    INTENT_SSO,
    INTENT_RECOVERY,
    INTENT_PREFS,
    INTENT_ACTIVATE_LOGINSHIELD,
    INTENT_SETUP,
    INTENT_REDIRECT,
];

// authentication mechanisms; TODO: this might be replaced by the authentication types like AUTHN_PASSWORD, AUTHN_LOGINSHIELD, etc. so look for usages of this and replace them
const MECHANISM_PASSWORD = 'password';
const MECHANISM_LOGINSHIELD = 'loginshield'; // this refers to authentication by private key signature, which we accomplish via the LoginShield authenticator app; TODO: maybe rename it to something more specific like 'dsig' for digital signature, or just 'shield' for digital signature with approved cipherset and possibly geolocation verification, etc.
const LIST_MECHANISM = [
    MECHANISM_PASSWORD,
    MECHANISM_LOGINSHIELD,
];

// access recovery status
const RECOVERY_STATUS_NEW = 'new';
const RECOVERY_STATUS_PENDING = 'pending';
const RECOVERY_STATUS_UNLOCKED = 'unlocked';
const RECOVERY_STATUS_RECOVERED = 'recovered';
const RECOVERY_STATUS_CANCELLED = 'cancelled';
const LIST_RECOVERY_STATUS = [
    RECOVERY_STATUS_NEW,
    RECOVERY_STATUS_PENDING,
    RECOVERY_STATUS_UNLOCKED,
    RECOVERY_STATUS_RECOVERED,
    RECOVERY_STATUS_CANCELLED,
];

// response types
const RESPONSE_TYPE_FAULT = 'fault';
const RESPONSE_TYPE_INTENT = 'intent';
const RESPONSE_TYPE_ITEM = 'item';
const RESPONSE_TYPE_LIST = 'list';
const RESPONSE_TYPE_STATUS = 'status';
const RESPONSE_TYPE_VALUE = 'value';
const LIST_RESPONSE_TYPE = [
    RESPONSE_TYPE_FAULT,
    RESPONSE_TYPE_INTENT,
    RESPONSE_TYPE_ITEM,
    RESPONSE_TYPE_LIST,
    RESPONSE_TYPE_STATUS,
    RESPONSE_TYPE_VALUE,
];

// alias modes (whether user enters email or pseudonym as first step of sign-in)
const USER_ALIAS_MODE_EMAIL = 'email';
const USER_ALIAS_MODE_PSEUDONYM = 'pseudonym';
const LIST_USER_ALIAS_MODE = [
    USER_ALIAS_MODE_EMAIL,
    USER_ALIAS_MODE_PSEUDONYM,
];

export {
    AUTHN_CRYPTIUM_ID,
    AUTHN_EMAIL,
    AUTHN_PSEUDONYM,
    AUTHN_PASSWORD,
    AUTHN_LOGINSHIELD,
    AUTHN_OTP,
    AUTHN_2FA,
    AUTHN_SETUP,
    FAULT_INVALID_EMAIL,
    FAULT_INVALID_PSEUDONYM,
    FAULT_INVALID_TOKEN,
    FAULT_NOT_REGISTERED,
    FAULT_UNAUTHORIZED,
    IA_LOGIN,
    IA_SERVICE_SETUP,
    IA_SHARE,
    IA_SIGNUP,
    IA_VERIFY_EMAIL,
    IA_RECOVERY,
    INTENT_LOGIN,
    INTENT_SIGNUP,
    INTENT_SSO,
    INTENT_RECOVERY,
    INTENT_PREFS,
    INTENT_ACTIVATE_LOGINSHIELD,
    INTENT_SETUP,
    INTENT_REDIRECT,
    LIST_AUTHN,
    LIST_IA,
    LIST_INTENT,
    LIST_MECHANISM,
    LIST_FAULT,
    LIST_RESPONSE_TYPE,
    LIST_USER_ALIAS_MODE,
    LIST_RECOVERY_STATUS,
    MECHANISM_PASSWORD, // deprecated
    MECHANISM_LOGINSHIELD, // deprecated
    RECOVERY_STATUS_NEW,
    RECOVERY_STATUS_PENDING,
    RECOVERY_STATUS_UNLOCKED,
    RECOVERY_STATUS_RECOVERED,
    RECOVERY_STATUS_CANCELLED,
    RESPONSE_TYPE_FAULT,
    RESPONSE_TYPE_INTENT,
    RESPONSE_TYPE_ITEM,
    RESPONSE_TYPE_LIST,
    RESPONSE_TYPE_STATUS,
    RESPONSE_TYPE_VALUE,
    USER_ALIAS_MODE_EMAIL,
    USER_ALIAS_MODE_PSEUDONYM,
};
