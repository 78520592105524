<template>
    <v-form @submit="submitPseudonym" onSubmit="return false;">
    <v-text-field
        label="Username"
        v-model="editablePseudonym"
        :color="primaryColor"
        outlined
        ref="pseudonymInput"
    >
        <template #prepend-inner>
            <font-awesome-icon :icon="['fas', 'user']" fixed-width class="mt-1"></font-awesome-icon>
        </template>
        <template #append-outer>
            <v-btn elevation="4" :style="primaryButtonStyle" @click="submitPseudonym" :disabled="!isPseudonymFormComplete">
                Next
            </v-btn>
        </template>
        <!-- <template #append-outer v-if="isPseudonymEditable">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn icon :color="primaryColor" @click="switchProfile()" :disabled="!isPseudonymEditable" v-on="on">
                        <span :color="primaryColor">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" size="1x"/>
                        </span>
                    </v-btn>
                </template>
                <span>Change account</span>
            </v-tooltip>
        </template> -->
    </v-text-field>
    <v-btn text class="px-0" :color="primaryColor" small @click="forgotPseudonym">Forgot username</v-btn>
    <!-- TODO xyzzy if we need to verify pseudonym address, user will be redirected to cryptium id so we won't be showing this message... -->
    <!--
    <v-alert border="left" :color="primaryColor" colored-border class="mt-8">
        <p class="ma-0 pa-0">Check your inbox for a link to continue.</p>
        <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="isPseudonymEditable">If you don't receive an pseudonym, check that the username is correct.</p>
    </v-alert>
    -->
</v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidPseudonym } from '@/sdk/input';

export default {
    props: ['id', /* 'allowSwitchUser', */ 'display', 'etag', 'pseudonym'],
    // props: {
    //     imageId: {
    //         type: String,
    //         default: null,
    //     },
    //     height: {
    //         type: Number,
    //     },
    //     width:
    // },

    data: () => ({
        editablePseudonym: null,
        pseudonymError: false,
        submitTimestamp: null,
    }),

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            palette: (state) => state.palette,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        // isPseudonymEditable() {
        //     return this.allowSwitchUser;
        // },
        isPseudonymFormComplete() {
            return typeof this.editablePseudonym === 'string' && this.editablePseudonym.length > 0 && isValidPseudonym(this.editablePseudonym);
        },
    },

    watch: {
        etag() {
            this.resetView();
        },
    },

    methods: {
        // async loadImage() {
        //     try {
        //         this.$store.commit('loading', { loadBrandImageByIntent: true });
        //         const response = await this.$client.brandprofile().image.get({ alias: this.alias, intent: this.intent, mode: this.mode });
        //         if (response) {
        //             this.contentType = response.content_type;
        //             this.content = response.content_base64;
        //         }
        //     } catch (err) {
        //         console.log('imagebox.vue loadImage failed', err);
        //     } finally {
        //         this.$store.commit('loading', { loadBrandImageByIntent: false });
        //     }
        // },
        // switchProfile() {
        //     this.resetView();
        //     this.editablePseudonym = '';
        // },
        async submitPseudonym() {
            try {
                if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitTimestamp = Date.now();
                this.$store.commit('loading', { loginSubmitPseudonym: true });
                const {
                    /* isAuthenticated, */
                    // intent, // could be 'signup' in conjunction with redirect, so we could show a card that says 'you need to sign up to continue, and here's the link' and show the redirect URL as a sign up link instead of automatically redirecting the user; it's our choice whether to show that step or redirect immediately
                    status,
                    redirect,
                    // mechanism,
                    // route,
                    // expires,
                    error,
                } = await this.$client.realm(this.$route.params.realm).authn.loginWithUserPseudonym(
                    this.id, // login request id
                    {
                        pseudonym: this.editablePseudonym,
                    },
                );

                // server may redirect user to Cryptium ID for pseudonym verification
                if (status === 'redirect' && redirect) {
                    if (redirect.startsWith('/')) {
                        this.$router.replace(redirect);
                    } else if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        window.location.href = redirect;
                    }
                    return;
                }

                if (error) {
                    console.error(`submitPseudonym received error: ${JSON.stringify(error)}`);
                    this.pseudonymError = true;
                }

                this.$emit('pseudonym', { pseudonym: this.pseudonym, status });
            } catch (err) {
                console.error('submitPseudonym error', err);
                this.pseudonymError = true;
            } finally {
                this.$store.commit('loading', { loginSubmitPseudonym: false });
            }
        },
        forgotPseudonym() {
            this.$emit('forgotPseudonym');
        },
        resetView() {
            this.editablePseudonym = this.pseudonym;
            this.pseudonymError = false;
            // this.pseudonymNotFoundError = false;
            // this.displayForgotPseudonymHint = false;
            if (this.display) {
                this.$refs.pseudonymInput.reset();
                this.$activateInput('pseudonymInput');
            }
        },
    },

    mounted() {
        /**
         * NOTE: The parent may mount this component but hide it, and in that case trying to focus on
         * the email input textfield won't work. For this reason, when the parent is ready to show
         * this component, it should also update the `etag` property to the current time to trigger
         * initializing the view.
         */
    },

};
</script>
