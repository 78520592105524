<template>
    <v-form @submit="loginPassword" onSubmit="return false;">
        <v-text-field
            outlined
            :color="primaryColor"
            v-model="password"
            label="Password"
            type="password"
            ref="passwordInput"
            @keydown="checkForCapsLock"
        >
            <template #prepend-inner>
                <font-awesome-icon :icon="['fas', 'lock']" fixed-width class="mt-1"></font-awesome-icon>
            </template>
            <template #append-outer>
                <v-btn tile elevation="4" :style="primaryButtonStyle" @click="loginPassword" :disabled="!isPasswordFormComplete">
                    Login
                </v-btn>
            </template>
        </v-text-field>

        <p class="text-caption">
            &nbsp; <!-- reserve the vertical space for this warning -->
            <span v-show="capsLockWarning">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="1x" class="amber--text text--darken-4"></font-awesome-icon>
            Caps Lock is ON
            </span>
        </p>

        <v-alert border="left" color="red" colored-border class="mt-2" v-if="unauthorizedError">
            <p class="ma-0 pa-0">The password is incorrect.</p>
            <!-- <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="realmWebsiteURL">
                Return to <a :href="realmWebsiteURL">{{ realmDisplayName }}</a> to start over with the login request.
            </p>
            <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="!realmWebsiteURL">
                Return to the application to start over with the login request.
            </p> -->
        </v-alert>

        <v-btn text class="px-0" :color="primaryColor" small @click="forgotPassword">Forgot password</v-btn>
    <!--
    <v-alert border="left" :color="primaryColor" colored-border class="mt-8">
        <p class="ma-0 pa-0">Check your inbox for a link to continue.</p>
        <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="isPseudonymEditable">If you don't receive an pseudonym, check that the username is correct.</p>
    </v-alert>
    -->
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { RESPONSE_TYPE_FAULT, FAULT_UNAUTHORIZED, RESPONSE_TYPE_INTENT /* INTENT_LOGIN, INTENT_SETUP, INTENT_REDIRECT */ } from '@/sdk/loginfront/login_api_constants.js'; // '@loginfront/login-api-constants-js';

export default {
    props: ['id'],
    // props: {
    //     imageId: {
    //         type: String,
    //         default: null,
    //     },
    //     height: {
    //         type: Number,
    //     },
    //     width:
    // },

    data: () => ({
        password: '',
        passwordError: false,
        unauthorizedError: false,
        submitTimestamp: null,
        capsLockWarning: false,
    }),

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            palette: (state) => state.palette,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        isPasswordFormComplete() {
            return typeof this.password === 'string' && this.password.length > 0;
        },
    },

    watch: {
        etag() {
            // this.loadImage();
        },
        focus() {
            // TODO: check if we are visible first
            this.$activateInput('passwordInput');
        },
    },

    methods: {
        // async loadImage() {
        //     try {
        //         this.$store.commit('loading', { loadBrandImageByIntent: true });
        //         const response = await this.$client.brandprofile().image.get({ alias: this.alias, intent: this.intent, mode: this.mode });
        //         if (response) {
        //             this.contentType = response.content_type;
        //             this.content = response.content_base64;
        //         }
        //     } catch (err) {
        //         console.log('imagebox.vue loadImage failed', err);
        //     } finally {
        //         this.$store.commit('loading', { loadBrandImageByIntent: false });
        //     }
        // },
        async loginPassword() {
            try {
                if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitTimestamp = Date.now();
                if (!this.isPasswordFormComplete) {
                    return;
                }
                this.$store.commit('loading', { loginPassword: true });
                this.passwordError = false;
                this.unauthorizedError = false;
                const response = await this.$client.realm(this.$route.params.realm).authn.loginWithPassword(
                    this.id, // login request id
                    {
                        password: this.password,
                    },
                );

                if (response.type === RESPONSE_TYPE_FAULT) {
                    const { fault } = response;
                    if (fault === FAULT_UNAUTHORIZED) {
                        this.unauthorizedError = true;
                    } else {
                        this.passwordError = true; // TODO: rename to unknown error?
                    }
                }

                if (response.type === RESPONSE_TYPE_INTENT && response.intent) {
                    this.$emit('password', response);
                    /*
                    switch (response.intent) {
                    case INTENT_REDIRECT: {
                        // server may redirect user to Cryptium ID for email verification
                        const redirect = response.intent_params?.redirect;
                        if (redirect) {
                            this.redirect = redirect;
                            if (typeof window.location.replace === 'function') {
                                window.location.replace(redirect);
                            } else {
                                window.location.href = redirect;
                            }
                        } else {
                            console.error(`submitEmail: unexpected response from server: ${JSON.stringify(response)}`);
                            this.emailError = true;
                        }
                        break;
                    }
                    case INTENT_LOGIN:
                    case INTENT_SETUP:
                        // email verification is complete, moving on to next authentication step or to new user setup
                        this.$emit('password', { intent: response.intent, intent_params: response.intent_params });
                        break;
                    default:
                        console.error(`submitEmail: unexpected response from server: ${JSON.stringify(response)}`);
                        this.emailError = true;
                        break;
                    }
                    */
                    return;
                }

                /*

                const { isAuthenticated, redirect, intent, error } = response;

                if (typeof isAuthenticated === 'boolean') {
                    await this.updateSession({ isAuthenticated });
                }

                if (redirect) {
                    window.location.href = redirect;
                    return;
                }

                if (isAuthenticated && error === 'redirect-origin-mismatch') {
                    this.redirectOriginMismatchError = true;
                    this.loginPasswordInput = false;
                    return;
                }

                if (isAuthenticated) {
                    this.redirectAfterLogin({ intent });
                    return;
                }

                if (error) {
                    console.error(`loginPassword error: ${error}`);
                }
                this.passwordError = true;
                this.resetLoginForm();
                */
            } catch (err) {
                if (err.response && [500, 502].includes(err.response.status)) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Service unavailable', message: 'This is temporary. Please try again.' });
                    return;
                }
                console.error('loginPassword failed', err);
                this.passwordError = true;
                this.password = '';
                this.$activateInput('passwordInput');
            } finally {
                this.$store.commit('loading', { loginPassword: true });
            }
        },
        forgotPassword() {
            console.log('forgotPassword');
            this.$emit('forgotPassword');
        },
        checkForCapsLock(event) {
            if (typeof event.getModifierState === 'function') {
                const isCapsLockOn = event.getModifierState('CapsLock');
                if (isCapsLockOn) {
                    this.capsLockWarning = true;
                } else {
                    this.capsLockWarning = false;
                }
            }
        },
    },

    mounted() {
        this.$activateInput('passwordInput');
    },

};
</script>
