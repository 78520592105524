<template>
    <v-form @submit="loginshield" onSubmit="return false;">
        <v-row justify="center" v-show="loginWithLoginShield" class="ma-0 pt-5" style="width: 100%;">
            <div id="loginshield-content" style="width: 100%; height: 600px;"></div>
        </v-row>
        <!-- <v-btn text class="px-0" :color="primaryColor" small @click="forgotPassword">Forgot password</v-btn> -->
    <!--
    <v-alert border="left" :color="primaryColor" colored-border class="mt-8">
        <p class="ma-0 pa-0">Check your inbox for a link to continue.</p>
        <p class="mx-0 mb-0 mt-2 pa-0 grey--text text--darken-1 font-weight-light" v-if="isPseudonymEditable">If you don't receive an pseudonym, check that the username is correct.</p>
    </v-alert>
    -->
    <v-row justify="center" class="mt-8" v-if="loginshieldStartError" no-gutters>
        <p class="text-body-1 font-weight-light red--text">Login failed</p>
    </v-row>
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { loginshieldInit } from '@loginshield/realm-client-browser';

export default {
    props: ['id'],
    // props: {
    //     imageId: {
    //         type: String,
    //         default: null,
    //     },
    //     height: {
    //         type: Number,
    //     },
    //     width:
    // },

    data: () => ({
        isActivatingLoginShield: false,
        loginshieldStartError: false,
    }),

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            palette: (state) => state.palette,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
    },

    watch: {
        etag() {
            // this.loadImage();
        },
    },

    methods: {
        // async loadImage() {
        //     try {
        //         this.$store.commit('loading', { loadBrandImageByIntent: true });
        //         const response = await this.$client.brandprofile().image.get({ alias: this.alias, intent: this.intent, mode: this.mode });
        //         if (response) {
        //             this.contentType = response.content_type;
        //             this.content = response.content_base64;
        //         }
        //     } catch (err) {
        //         console.log('imagebox.vue loadImage failed', err);
        //     } finally {
        //         this.$store.commit('loading', { loadBrandImageByIntent: false });
        //     }
        // },
        async loginshield() {
            try {
                // .....  do nothing, everything is in the iframe
            } catch (err) {
                // if (err.response && [500, 502].includes(err.response.status)) {
                //     this.$bus.$emit('snackbar', { type: 'error', headline: 'Service unavailable', message: 'This is temporary. Please try again.' });
                //     return;
                // }
                // console.error('loginPassword failed', err);
                // this.passwordError = true;
                // this.password = '';
                // this.$activateInput('passwordInput');
            } finally {
                // this.$store.commit('loading', { loginPassword: true });
            }
        },
        async startLoginShield({
            mode, username, interactionId, nextRoute, rememberMe,
        }) {
            this.resetErrors();
            console.log(`startLoginShield: dispatch login with interactionId ${interactionId}`);
            try {
                this.$store.commit('loading', { loginWithLoginShield: true });
                // NOTE: userAlias and mode are used to initiate a login; token is used to finish a login request
                const { error, forward, interactionId: nextInteractionId } = await this.$client.realm(this.$route.params.realm).authn.loginWithLoginShield({
                    interactionId, // e.g. create_account or require_login interaction
                    nextRoute, // a path to another view, with optional query parameters; for when we want to return the user somewhere after login but there's no interaction id
                    username,
                    mode,
                });

                console.log(`startLoginShield: response nextInteractionId ${nextInteractionId}`);
                if (nextInteractionId) {
                    this.interactionId = nextInteractionId;
                }
                if (forward) {
                    let loginMode = null;
                    if (mode === 'activate-loginshield') {
                        loginMode = 'link-device'; // disable notifications for this login request
                    }
                    // redirect to loginshield for login
                    // this works, it's the redirect method: window.location = forward;
                    loginshieldInit({
                        elementId: 'loginshield-content',
                        backgroundColor: '#efefef',
                        // width: '100%',
                        // height: '600px',
                        action: 'start',
                        mode: loginMode,
                        forward,
                        rememberMe,
                        onResult: this.onResult.bind(this),
                        /*
                        onLogin: ((verifyInfo) => {
                            this.finishLoginShield(verifyInfo);
                        }),
                        onError: ((err) => {
                            console.log('startLoginShield: login failed, error: %o', err);
                            this.loginshieldStartError = true;
                            this.resetLoginForm();
                        }),
                        */
                    });
                } else {
                    // TODO: show a more specific error message that the account either doesn't exist or
                    // doesn't have loginshield enabled, or isn't ready to activate loginshield
                    if (error) {
                        console.error(`startLoginShield error: ${error}`);
                    }
                    if (this.intent === 'activate-loginshield') {
                        this.$router.replace({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm } }); // TODO: this.redirectAfterLogin(); ?
                        return;
                    }
                    this.loginshieldStartError = true;
                    this.resetLoginForm();
                }
            } finally {
                this.$store.commit('loading', { loginWithLoginShield: false });
            }
        },
        async resumeLoginShield({ forward, rememberMe }) {
            this.resetErrors();
            loginshieldInit({
                elementId: 'loginshield-content',
                backgroundColor: '#efefef',
                // width: '100%',
                // height: '600px',
                action: 'resume',
                forward,
                rememberMe,
                onResult: this.onResult.bind(this),
                /*
                onLogin: ((verifyInfo) => {
                    this.finishLoginShield(verifyInfo);
                }),
                onError: ((err) => {
                    console.log('resumeLoginShield: login failed, error: %o', err);
                    this.loginshieldStartError = true;
                    this.resetLoginForm();
                }),
                */
            });
        },
        async finishLoginShield({ verifyToken }) {
            console.log(`finishLoginShield: verifying login with token: ${verifyToken} interactionId: ${this.interactionId}`);
            try {
                this.$store.commit('loading', { loginWithLoginShield: true });
                // NOTE: username and mode are used to initiate a login; token is used to finish a login request
                const {
                    isAuthenticated,
                    redirect,
                    intent,
                    next,
                    error,
                } = await this.$client.realm(this.$route.params.realm).authn.loginWithLoginShield({
                    // mode: 'verify-loginshield',  mode is not even needed, if we send the token the service knows to use it to verify the login ;
                    verifyToken,
                    interactionId: this.interactionId, // e.g. points to an create-account or login interaction
                });

                if (typeof isAuthenticated === 'boolean') {
                    await this.updateSession({ isAuthenticated });
                }

                if (redirect) {
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        window.location.href = redirect;
                    }
                    return;
                }

                if (isAuthenticated && error === 'redirect-origin-mismatch') {
                    this.redirectOriginMismatchError = true;
                    this.loginWithLoginShield = false;
                    return;
                }

                if (isAuthenticated) {
                    this.redirectAfterLogin({ intent, nextInteractionId: next });
                    return;
                }

                if (error) {
                    console.error(`finishLoginShield error: ${error}`);
                    this.loginshieldStartError = true;
                    this.resetLoginForm();
                    // TODO: if the error is 'unauthorized' show links to login or create account (the
                    // two interactions that are available, and we don't indicate to the user which was
                    // involved in the requested interaction, since they don't have access) by redirect
                    // user to front page
                    if (error === 'unauthorized') {
                        this.$router.replace({ name: 'realm-interaction', query: { error: 'unauthorized' }, params: { realm: this.$route.params.realm } });
                    }
                } else {
                    // TODO: show a loginshield specific error and then try loginshield login again,
                    // because this situation could happen when a phishing attack is circumvented,
                    // and the second login would succeed; in this case the call returns
                    // isAuthenticated: false, but no error, because the processing is routine
                    console.error('finishLoginShield not authenticated');
                    this.loginshieldStartError = true;
                    this.resetLoginForm();
                }

                console.log(`finishLoginShield: isAuthenticated ${isAuthenticated} next ${next}`);
            } finally {
                this.$store.commit('loading', { loginWithLoginShield: false });
            }
        },
        onResult(result) {
            switch (result.status) {
            case 'verify':
                this.finishLoginShield({ verifyToken: result.verifyToken });
                break;
            case 'error':
                if (this.intent === 'activate-loginshield') {
                    this.$router.replace({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm } }); // TODO: this.redirectAfterLogin(); ?
                    return;
                }
                this.loginshieldStartError = true;
                this.resetLoginForm();
                break;
            case 'cancel':
                if (this.intent === 'activate-loginshield') {
                    this.$router.replace({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm } }); // TODO: this.redirectAfterLogin(); ?
                    return;
                }
                this.resetLoginForm();
                break;
            default:
                console.error(`Login.vue: onResult: unknown status ${result.status}`);
            }
        },
        resetLoginForm() {
            console.log('LoginShieldForm: resetLoginForm');
        },
        // TODO: this is the access recovery event for loginshield -- because if authenticator was lost, damaged, stolen, or reset, it appears as a new authenticator
        newAuthenticator() {
            this.$emit('newAuthenticator');
        },
    },

    mounted() {
        // this.$activateInput('passwordInput');
        /*
                if (intent === 'activate-loginshield') {
                    this.intent = intent;

                    // new user registration or reset
                    console.log('login: activate loginshield mode');
                    this.isActivatingLoginShield = true;
                    setTimeout(() => {
                        this.$nextTick(() => this.startLoginShield({
                            mode: 'activate-loginshield',
                            interactionId: this.interactionId,
                            nextRoute: this.next,
                            username: this.userAlias, // TODO: when loginfront is merged with loginshield, it will be for whatever alias (pseudonym or email) the user already entered
                            rememberMe: true, / * this.isRememberMeChecked * /
                        }));
                    }, 1);
                    return;
                } else {
                    this.startLoginShield({
                        username: this.userAlias,
                        interactionId: this.interactionId,
                        nextRoute: this.next,
                        rememberMe: true, // this.isRememberMeChecked
                    });
                }
        */
    },

};
</script>
